import React from "react";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { UserCounters } from "../types";
import works from "../assets/works.svg";
import collections from "../assets/collections.svg";
import books from "../assets/books.svg";
import videos from "../assets/videos.svg";
import contests from "../assets/contests.svg";
import achievements from "../assets/achievements.svg";

interface UserScoresProps {
  userCounters: UserCounters | null;
  isMobile: boolean | null;
}

const formatValue = (value: number | undefined) => {
  return value !== undefined ? String(value).padStart(2, "0") : "00";
};

const UserScores: React.FC<UserScoresProps> = ({ userCounters }) => {
  return (
    <Box mt={4}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {[
          { label: "Works", value: userCounters?.books, icon: works },
          {
            label: "Collections",
            value: userCounters?.collections,
            icon: collections,
          },
          { label: "Books", value: userCounters?.books, icon: books },
          { label: "Videos", value: userCounters?.masterpiece, icon: videos },
          { label: "Contests", value: userCounters?.contests, icon: contests },
          {
            label: "Achievements",
            value: userCounters?.achievements,
            icon: achievements,
          },
        ].map(({ label, value, icon }, idx) => (
          <Grid key={idx} size={{ xs: 6, md: 2 }} sx={{ textAlign: "center" }}>
            <img src={icon} alt={label} />
            <Typography>{label}</Typography>
            <Typography>({formatValue(value)})</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default UserScores;
