import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

// Create a theme with custom typography
const theme = createTheme({
  typography: {
    fontFamily: 'Somatic, Arial, sans-serif', // Add Somatic font and fallback options
    h1: {
      fontFamily: 'Somatic, Arial, sans-serif',
    },
    h2: {
      fontFamily: 'Somatic, Arial, sans-serif',
    },
    h3: {
      fontFamily: 'Somatic, Arial, sans-serif',
    },
    h4: {
      fontFamily: 'Somatic, Arial, sans-serif',
    },
    h5: {
      fontFamily: 'Somatic, Arial, sans-serif',
    },
    h6: {
      fontFamily: 'Somatic, Arial, sans-serif',
    },
    body1: {
      fontFamily: 'Somatic, Arial, sans-serif',
      fontSize: "14.7px"
    },
    body2: {
      fontFamily: 'Somatic, Arial, sans-serif',
    },
    button: {
      fontFamily: 'Somatic, Arial, sans-serif',
    },
  },
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);

reportWebVitals(console.log);
