import React from "react";
import { Box, Avatar, Grid2 as Grid, Typography } from "@mui/material";
import { UserCounters, UserDetail } from "../types";
import UserBannerScores from "./user-banner-scores";

interface UserAvatarInfoProps {
  userDetail: UserDetail | null;
  userCounters: UserCounters | null;
  isMobile: Boolean | null;
}

const UserAvatarInfo: React.FC<UserAvatarInfoProps> = ({
  userDetail,
  userCounters,
  isMobile,
}) => {
  return (
    <>
      <Grid size={{ xs: 12, md: 2 }}>
        <Avatar
          alt={userDetail?.handle}
          src={
            userDetail?.defaultAvatar
              ? `${process.env.REACT_APP_DEFAULT_AVATAR}/APP/UserAvatars/Avatar2.png`
              : `${process.env.REACT_APP_DEFAULT_AVATAR}/${userDetail?.uid}/PROFILE/IMAGES/raw/${userDetail?.avatar}`
          }
          sx={{ width: 142, height: 142, m: isMobile ? "auto" : "inherit" }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 5 }}>
        <Typography variant="h5" align={isMobile ? "center" : "inherit"}>
          {userDetail?.firstname || "Anonymous"}
        </Typography>
        <Typography variant="body1" align={isMobile ? "center" : "inherit"}>
          {userDetail?.bio}
        </Typography>
      </Grid>
      <UserBannerScores userCounters={userCounters} isMobile={isMobile} />
    </>
  );
};

export default UserAvatarInfo;
