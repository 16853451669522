import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import star from "../assets/star.svg";
import heart from "../assets/heart.svg";
import smiley_background from "../assets/smiley_background.svg";
import great from "../assets/great.svg";
import lovely from "../assets/lovely.svg";
import omg from "../assets/omg.svg";
import epic from "../assets/epic.svg";
import axiosInstance from "../api/counterConfig";
import { CounterData } from "../types";
import { POST_DETAIL_API } from "../api/endpoint";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    backdropFilter: "blur(40px)",
  },
  "& .MuiDialog-paper": {
    borderRadius: "18px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
    width: "100%",
    maxWidth: 700,
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(0),
  },
}));

interface PostDetailDialogProps {
  open: boolean;
  onClose: () => void;
  postDetail: {
    post_id: string;
    imageUrl: string;
    user_id: string;
    files: { name: string }[];
    text: string;
  } | null;
}

const PostDetailDialog: React.FC<PostDetailDialogProps> = ({
  open,
  onClose,
  postDetail,
}) => {
  const [counterData, setCounterData] = useState<CounterData | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if mobile

  const fetchCounters = async () => {
    if (postDetail) {
      try {
        const response = await axiosInstance.get(
          `${POST_DETAIL_API}/${postDetail.user_id}/${postDetail.post_id}`,
        );
        const { Item } = response.data;
        setCounterData(Item); // Set the entire Item object in counterData
      } catch (error) {
        console.log("Error fetching counter data:", error);
      }
    }
  };

  useEffect(() => {
    fetchCounters();
  }, [postDetail]);

  if (!postDetail || !counterData) return null;

  const imageUrl =
    postDetail?.imageUrl ||
    (postDetail?.files?.length
      ? `${process.env.REACT_APP_DEFAULT_AVATAR}/${postDetail.user_id}/WORKS/IMAGES/raw/${postDetail.files[0].name}`
      : "");

  return (
    <BootstrapDialog
      open={open}
      onClose={onClose}
      sx={{ m: 0, p: 0 }}
      maxWidth="md"
    >
      <DialogContent
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: isMobile ? "auto" : 400,
          maxHeight: "80vh",
          overflow: "hidden",
        }}
      >
        {/* Blurred background image */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${imageUrl || ""})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: "blur(20px)",
            zIndex: 0,
          }}
        ></div>

        {/* Main image in the center */}
        <img
          src={imageUrl || ""}
          alt={postDetail.text}
          style={{
            height: isMobile ? "200px" : "400px",
            maxWidth: "100%",
            borderRadius: "10px",
            objectFit: "contain",
            zIndex: 1,
            position: "relative",
          }}
        />
      </DialogContent>

      {/* Counters Section */}
      <div style={{ display: "flex", alignItems: "center", marginTop: 16 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            marginRight: 8,
          }}
        >
          <span
            style={{
              width: 60,
              height: 60,
              borderRadius: "10px",
            }}
          >
            <img
              src={star || ""}
              alt="star"
              style={{ width: "100%", height: "100%", borderRadius: "10px" }}
            />
          </span>
          <Typography variant={isMobile ? "body1" : "h5"}>
            {counterData?.cws?.N ? parseInt(counterData.cws.N, 10) : 0}
          </Typography>
        </div>

        {/* Emoji-based Counters */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 5,
            backgroundImage: `url(${smiley_background})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: 70,
            justifyContent: "space-evenly",
          }}
        >
          {[
            { img: great, label: "GREAT", value: counterData?.cps?.N },
            { img: lovely, label: "LOVELY", value: counterData?.likes?.N },
            { img: omg, label: "OMG", value: counterData?.views?.N },
            { img: epic, label: "EPIC", value: counterData?.cws?.N },
          ].map(({ img, label, value }) => (
            <div
              key={label}
              style={{ display: "flex", alignItems: "center", color: "white" }}
            >
              <img
                src={img || ""}
                alt={label}
                style={{ width: "35px", height: "35px", borderRadius: "10px" }}
              />
              <span>
                <Typography variant={isMobile ? "body2" : "body1"}>
                  {label}
                </Typography>
                <Typography variant={isMobile ? "body2" : "body1"}>
                  {value ? parseInt(value, 10) : 0}
                </Typography>
              </span>
            </div>
          ))}
        </div>

        {/* Likes */}
        <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
          <img
            src={heart || ""}
            alt="heart"
            style={{
              width: 45,
              height: 45,
              borderRadius: "10px",
              marginRight: 8,
            }}
          />
          <Typography variant={isMobile ? "body1" : "h5"}>
            {counterData?.likes?.N ? parseInt(counterData.likes.N, 10) : 0}
          </Typography>
        </div>
      </div>
    </BootstrapDialog>
  );
};

export default PostDetailDialog;
