import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../api/axiosConfig";
import counterInstance from "../api/counterConfig";
import {
  Box,
  Typography,
  useMediaQuery,
  CircularProgress,
  Grid2 as Grid,
} from "@mui/material";
import { UserDetail, UserCounters, ContentProps } from "../types";
import UserStats from "../components/user-stats";
import UserBadges from "../components/user-badges";
import UserAvatarInfo from "../components/user-avatar-info";
import UserScores from "../components/user-scores";
import Latest from "../components/latest";
import { USER_BY_IDS_API, USER_COUNTER_API } from "../api/endpoint";

// Helper function to parse counters
const parseCounter = (counter: { N: string } | undefined): number =>
  parseInt(counter?.N || "0", 10);

const Content: React.FC<ContentProps> = ({
  userCounters,
  counterError,
  isMobile,
  userId,
}) => {
  return (
    <>
      <UserStats
        userCounters={userCounters}
        counterError={counterError}
        isMobile={isMobile}
        gradientTextStyles={gradientTextStyles}
      />
      <UserBadges
        userCounters={userCounters}
        counterError={counterError}
        isMobile={isMobile}
        gradientTextStyles={gradientTextStyles}
      />
      <Latest isUserProfile={true} userId={userId} />
    </>
  );
};

const UserProfile: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [userDetail, setUserDetail] = useState<UserDetail | null>(null);
  const [userCounters, setUserCounters] = useState<UserCounters | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [userError, setUserError] = useState<string | null>(null);
  const [counterError, setCounterError] = useState<string | null>(null);

  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const fetchUserDetail = async () => {
      try {
        const userResponse = await axiosInstance.get(
          `${USER_BY_IDS_API}${userId}`,
        );
        const userData = userResponse.data?.data?.[0];
        userData
          ? setUserDetail(userData)
          : setUserError("User data not found.");
      } catch {
        setUserError("Error fetching user details.");
      }
    };

    const fetchUserCounters = async () => {
      try {
        const countersResponse = await counterInstance.get(
          `${USER_COUNTER_API}/${userId}`,
        );
        const counters = countersResponse.data?.Item;
        counters
          ? setUserCounters({
              posts: parseCounter(counters.posts),
              followers: parseCounter(counters.followers),
              following: parseCounter(counters.following),
              likes: parseCounter(counters.cws),
              books: parseCounter(counters.books),
              cps: parseCounter(counters.cps),
              collections: parseCounter(counters.collections),
              masterpiece: parseCounter(counters.masterpiece),
              achievements: parseCounter(counters.achievements),
              silver: parseCounter(counters.silver),
              active: parseCounter(counters.active),
              contests: parseCounter(counters.contests),
              ruby: parseCounter(counters.ruby),
              gold: parseCounter(counters.gold),
              featured_posts: parseCounter(counters.featured_posts),
              milestones: parseCounter(counters.milestones),
              friends: parseCounter(counters.friends),
              cws_for_coin: parseCounter(counters.cws_for_coin),
              rank: parseCounter(counters.rank),
              cgs: parseCounter(counters.cgs),
              diamond: parseCounter(counters.diamond),
              cas: parseCounter(counters.cas),
              coins: parseCounter(counters.coins),
              hall_of_fame: parseCounter(counters.hall_of_fame),
              wonders: parseCounter(counters.wonders),
              century: parseCounter(counters.century),
            })
          : setCounterError("User counters not found.");
      } catch {
        setCounterError("Error fetching user counters.");
      }
    };

    setLoading(true);
    fetchUserDetail();
    fetchUserCounters();
    setLoading(false);
  }, [userId]);

  if (loading) return <CircularProgress />;

  return isMobile ? (
    <>
      <Box
        sx={{
          flexGrow: 1,
          ml: isMobile ? 2 : 10,
          mr: isMobile ? 2 : 10,
          mt: isMobile ? 2 : 8,
        }}
      >
        <Grid container spacing={1} justifyContent="space-between">
          {userError ? (
            <Typography color="error">{userError}</Typography>
          ) : (
            <UserAvatarInfo
              userDetail={userDetail}
              userCounters={userCounters}
              isMobile={isMobile}
            />
          )}
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "38px 38px 0px 0px",
          boxShadow:
            "0px -4px 8px 0px #0000000D, 0px 4px 7.8px 0px #00000040 inset",
        }}
      >
        <UserScores userCounters={userCounters} isMobile={isMobile} />
        <Content
          userCounters={userCounters}
          counterError={counterError}
          isMobile={isMobile}
          userId={userId!}
        />
      </Box>
    </>
  ) : (
    <Box
      sx={{
        flexGrow: 1,
        ml: isMobile ? 2 : 10,
        mr: isMobile ? 2 : 10,
        mt: isMobile ? 2 : 8,
      }}
    >
      <Grid container spacing={1} justifyContent="space-between">
        {userError ? (
          <Typography color="error">{userError}</Typography>
        ) : (
          <UserAvatarInfo
            userDetail={userDetail}
            userCounters={userCounters}
            isMobile={isMobile}
          />
        )}
      </Grid>
      <UserScores userCounters={userCounters} isMobile={isMobile} />
      <Content
        userCounters={userCounters}
        counterError={counterError}
        isMobile={isMobile}
        userId={userId!}
      />
    </Box>
  );
};

// Styles function for gradient text
const gradientTextStyles = (isMobile: boolean) => ({
  background:
    "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  fontWeight: isMobile ? 700 : 900,
  letterSpacing: isMobile ? 3 : 12,
  mb: isMobile ? 2 : 5,
});

export default UserProfile;
