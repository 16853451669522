import React from "react";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { UserCounters } from "../types";
import diamond from "../assets/diamond.svg";
import ruby from "../assets/ruby.svg";
import gold from "../assets/gold.svg";
import silver from "../assets/silver.svg";

interface UserStatsProps {
  userCounters: UserCounters | null;
  counterError: string | null;
  isMobile: Boolean | null;
  gradientTextStyles: Function;
}

const UserStats: React.FC<UserStatsProps> = ({
  userCounters,
  counterError,
  isMobile,
  gradientTextStyles,
}) => {
  const formatValue = (value: number | undefined) => {
    return value !== undefined ? String(value).padStart(2, "0") : "00";
  };

  return (
    <Box
      mt={4}
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography variant="h4" sx={gradientTextStyles(isMobile)}>
        VALUABLES
      </Typography>
      <Grid container spacing={10} justifyContent="center">
        {counterError ? (
          <Typography color="error">{counterError}</Typography>
        ) : (
          <>
            {[
              { label: "Diamond", value: userCounters?.diamond, icon: diamond },
              { label: "Ruby", value: userCounters?.ruby, icon: ruby },
              { label: "Gold", value: userCounters?.gold, icon: gold },
              { label: "Silver", value: userCounters?.silver, icon: silver },
            ].map(({ label, value, icon }, idx) => (
              <Grid
                key={idx}
                size={{ xs: 6, md: 3 }}
                sx={{ textAlign: "center" }}
              >
                <img src={icon} alt={label} />
                <Typography>{label}</Typography>
                <Typography>({formatValue(value)})</Typography>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default UserStats;
