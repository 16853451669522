import React from "react";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { UserCounters } from "../types";
import star_coin from "../assets/star_coin.svg";

interface UserBannerScoresProps {
  userCounters: UserCounters | null;
  isMobile: Boolean | null;
}

const UserBannerScores: React.FC<UserBannerScoresProps> = ({
  userCounters,
  isMobile,
}) => {
  const formatScore = (score: number | undefined) => {
    return score !== undefined ? String(score).padStart(2, "0") : "00";
  };

  return (
    <>
      <Grid size={{ xs: 12, md: 3 }}>
        <Grid container spacing={2} justifyContent="center" direction="column">
          {[
            {
              name: "Activity",
              score: userCounters?.cws_for_coin,
              gradient:
                "linear-gradient(90deg, #081058 -151.39%, #7E31E1 -18.16%, #9421CD 100%)",
              shadow: "rgba(14, 0, 60, 0.5)",
            },
            {
              name: "Popularity",
              score: userCounters?.cps,
              gradient:
                "linear-gradient(233.66deg, #FFAE01 19.66%, #FF8F01 77.25%)",
              shadow: "rgba(255, 175, 1, 0.5)",
            },
            {
              name: "Benevolence",
              score: userCounters?.cas,
              gradient:
                "linear-gradient(181.5deg, #EE4343 -2.29%, #FF0000 99.25%)",
              shadow: "rgba(238, 67, 67, 0.5)",
            },
            {
              name: "Resilience",
              score: userCounters?.cas,
              gradient: "linear-gradient(90deg, #14BA56 0%, #FFC700 100%)",
              shadow: "rgba(20, 186, 86, 0.5)",
            },
          ].map(({ name, score, gradient, shadow }) => (
            <Grid
              key={name}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    position: "relative",
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%",
                    background: gradient,
                    boxShadow: `0 4px 10px ${shadow}`,
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: "-3px",
                      left: "-3px",
                      right: "-3px",
                      bottom: "-3px",
                      background: gradient,
                      borderRadius: "50%",
                      opacity: 0.4,
                      zIndex: -1,
                    },
                  }}
                />
                <Typography variant="h6" sx={{ ml: 2 }}>
                  {name} Score
                </Typography>
              </Box>
              <Typography variant="h6" sx={{ ml: 2 }}>
                : {formatScore(score)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        size={{ xs: 12, md: 2 }}
        sx={{
          display: "flex",
          justifyContent: isMobile ? "center" : "normal",
          alignItems: isMobile ? "center" : "normal",
        }}
      >
        <img src={star_coin} alt="star coin" width={100} height={100} />
        <Box mt={2}>
          <Typography variant="h3">1276</Typography>
          <Typography variant="h3">1276</Typography>
        </Box>
      </Grid>
    </>
  );
};

export default UserBannerScores;
