import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import { Typography, IconButton, LinearProgress } from "@mui/material";
import axiosInstance from "../api/axiosConfig";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { useSwipeable } from "react-swipeable";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Champ, UserDetail } from "../types";
import { USER_BY_IDS_API, USERS_API } from "../api/endpoint";

const StarChamps = () => {
  const [champs, setChamps] = useState<Champ[]>([]);
  const [userDetails, setUserDetails] = useState<UserDetail[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<string | null>(null);
  const isFetching = useRef(false);
  const [activeSlide, setActiveSlide] = useState(2);
  const [stopExecution, setStopExecution] = useState<boolean | null>(false);

  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:600px)");

  const slideWidth = isMobile ? 127.55 : 360;
  const slideHeight = isMobile ? 127.55 : 432;

  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    delta: 10,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const fetchChamps = async (pageToken: string | null = null) => {
    if (!stopExecution) {
      if (isFetching.current) return;
      isFetching.current = true;

      try {
        const response = await axiosInstance.get(USERS_API, {
          params: { page: pageToken || null, count: 5 },
        });

        if (!response?.data?.data?.length) {
          setStopExecution(true);
        }
        setChamps((prevChamps) => [...prevChamps, ...response.data.data]);
        setCurrentPage(response.data.page);

        const userIds = response.data.data.map((champ: Champ) => champ.user_id);
        if (userIds.length > 0) {
          const userDetailsResponse = await axiosInstance.get(
            `${USER_BY_IDS_API}${userIds.join(",")}`,
          );
          setUserDetails((prevDetails) => {
            const existingIds = new Set(prevDetails.map((user) => user.uid));
            const newDetails = userDetailsResponse.data.data.filter(
              (user: UserDetail) => !existingIds.has(user.uid),
            );
            return [...prevDetails, ...newDetails];
          });
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(error.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
        isFetching.current = false;
      }
    }
  };

  useEffect(() => {
    fetchChamps();
  }, []);

  useEffect(() => {
    if (activeSlide === sliderData.length - 1) {
      fetchChamps(currentPage);
    }
  }, [activeSlide, currentPage]);

  const sliderData = champs.map((champ, index) => {
    const userDetail = userDetails.find((user) => user.uid === champ.user_id);
    return {
      id: champ.user_id + index,
      bgColor: "white",
      icon: (
        <img
          src={
            userDetail?.defaultAvatar
              ? `${process.env.REACT_APP_DEFAULT_AVATAR}/APP/UserAvatars/Avatar2.png`
              : `${process.env.REACT_APP_DEFAULT_AVATAR}/${userDetail?.uid}/PROFILE/IMAGES/raw/${userDetail?.avatar}`
          }
          alt={userDetail?.handle}
          width={slideWidth}
          height={slideHeight}
          className="slide-img"
          onClick={() => navigate(`/user/${champ.user_id}`)}
        />
      ),
      title: userDetail?.firstname || "Anonymous",
    };
  });

  const next = () => {
    if (activeSlide < sliderData.length - 1) {
      setActiveSlide(activeSlide + 1);
    }
  };

  const prev = () => {
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
    }
  };

  const goToSlide = (index: number) => {
    setActiveSlide(index);
  };

  const getStyles = (index: number) => {
    const screenWidth = window.innerWidth;
    const baseTranslateX = isMobile ? screenWidth * 0.2 : screenWidth * 0.25;
    const baseTranslateZ = isMobile ? screenWidth * 0.25 : screenWidth * 0.3;

    if (activeSlide === index) {
      return {
        opacity: 1,
        transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
        zIndex: 10,
      };
    } else if (activeSlide - 1 === index) {
      return {
        opacity: 1,
        transform: `translateX(-${baseTranslateX}px) translateZ(-${baseTranslateZ}px) rotateY(35deg)`,
        zIndex: 9,
      };
    } else if (activeSlide + 1 === index) {
      return {
        opacity: 1,
        transform: `translateX(${baseTranslateX}px) translateZ(-${baseTranslateZ}px) rotateY(-35deg)`,
        zIndex: 9,
      };
    } else if (activeSlide - 2 === index) {
      return {
        opacity: 1,
        transform: `translateX(-${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(35deg)`,
        zIndex: 8,
      };
    } else if (activeSlide + 2 === index) {
      return {
        opacity: 1,
        transform: `translateX(${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(-35deg)`,
        zIndex: 8,
      };
    } else if (index < activeSlide - 2) {
      return {
        opacity: 0,
        transform: `translateX(-${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(35deg)`,
        zIndex: 7,
      };
    } else if (index > activeSlide + 2) {
      return {
        opacity: 0,
        transform: `translateX(${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(-35deg)`,
        zIndex: 7,
      };
    }
  };

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant={isMobile ? "h6" : "h4"}
        sx={{
          background:
            "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          color: "transparent",
          fontWeight: isMobile ? 700 : 900,
          letterSpacing: isMobile ? 3 : 12,
          mb: isMobile ? 4 : 5,
        }}
      >
        STAR CHAMPS
      </Typography>
      {loading ? (
        <LinearProgress sx={{ width: "100%" }} color="secondary" />
      ) : (
        <Box sx={{ width: "100%", maxWidth: "80%" }}>
          <div
            className="slideC"
            {...handlers}
            style={{ width: slideWidth, height: slideHeight }}
          >
            {sliderData.map((item, i) => (
              <div
                key={item.id}
                className="slide"
                style={{
                  width: slideWidth,
                  height: slideHeight,
                  background: item.bgColor,
                  boxShadow: `0 5px 20px ${item.bgColor}30`,
                  ...getStyles(i),
                }}
                onClick={() => goToSlide(i)}
              >
                <div className="sliderContent">
                  {item.icon}
                  <Typography color="black" align="center">
                    {item.title}
                  </Typography>
                </div>
              </div>
            ))}
          </div>

          {/* Navigation Buttons */}
          <div className="btns">
            <IconButton
              className="slider-btn prev-btn"
              onClick={prev}
              sx={{
                color: "red",
              }}
              aria-label="Previous slide"
            >
              <ArrowBack />
            </IconButton>
            <IconButton
              className="slider-btn next-btn"
              onClick={next}
              sx={{
                color: "red",
              }}
              aria-label="Next slide"
            >
              <ArrowForward />
            </IconButton>
          </div>
        </Box>
      )}
    </Box>
  );
};

export default StarChamps;
