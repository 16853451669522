import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import StartChamps from "../components/star-champs";
import TopPortraits from "../components/top-portraits";
import TopLandscapes from "../components/top-landscapes";
import Popular from "../components/popular";
import TopBooks from "../components/top-books";
import TrendingHot from "../components/trending-hot";
import Latest from "../components/latest";

const Content = (props: { isMobile: any }) => {
  const isMobile = props.isMobile;
  const marginTop = isMobile ? 2 : 5;
  const marginBottom = isMobile ? 2 : 5;
  return (
    <Box
      sx={{
        backgroundSize: "repeat",
        backgroundPosition: "center center",
        zIndex: 0,
        padding: "20px",
      }}
    >
      <TrendingHot />
      <Box sx={{ mt: marginTop, mb: marginBottom }} />

      {/* STAR CHAMPS section */}
      <Box sx={{ mt: marginTop, mb: marginBottom }} />
      <StartChamps />
      <Box sx={{ mt: marginTop, mb: marginBottom }} />
      <TopPortraits />
      <Box sx={{ mt: marginTop, mb: marginBottom }} />
      <TopLandscapes />
      <Box sx={{ mt: marginTop, mb: marginBottom }} />
      <Popular />
      <Box sx={{ mt: marginTop, mb: marginBottom }} />
      <TopBooks />
      <Box sx={{ mt: marginTop, mb: marginBottom }} />
      <Latest />
    </Box>
  );
};

const Home = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return isMobile ? (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "38px 38px 0px 0px",
        boxShadow:
          "0px -4px 8px 0px #0000000D, 0px 4px 7.8px 0px #00000040 inset",
      }}
    >
      <Content isMobile={isMobile} />
    </Box>
  ) : (
    <Box sx={{ margin: 0, padding: 0, marginTop: "-13%" }}>
      <Content isMobile={isMobile} />
    </Box>
  );
};

export default Home;
